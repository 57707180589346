const FaqsIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_412_4351)">
                <path
                    d="M23 18.3722V0.798789C23 0.363164 22.6308 0 22.1879 0H1.81208C1.36921 0 1 0.36319 1 0.798789V18.3722C1 18.8078 1.36923 19.1709 1.81208 19.1709H5.09732V23.2012C5.09732 23.4917 5.28193 23.7822 5.54019 23.9273C5.65091 24 5.7987 24 5.90942 24C6.09404 24 6.24158 23.9638 6.38937 23.8549L12.9968 19.1347H22.188C22.6309 19.1709 22.9998 18.8077 22.9998 18.3721L23 18.3722ZM21.3758 17.5734H12.7382C12.5536 17.5734 12.406 17.6096 12.2582 17.7185L6.75838 21.6398V18.3722C6.75838 17.9366 6.38914 17.5734 5.9463 17.5734H2.66106V1.5976H21.3758L21.3758 17.5734Z"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0.6"
                />
                <path
                    d="M10.2039 8.43563C10.6268 8.43563 10.9794 8.07047 10.9794 7.6325C10.9794 6.7563 11.8958 6.71986 12.0015 6.71986C12.1072 6.71986 13.0237 6.75627 13.0237 7.6325V8.36255L11.6489 9.1293C11.402 9.27522 11.226 9.53088 11.226 9.85936V10.7356C11.226 11.1736 11.5786 11.5387 12.0015 11.5387C12.4244 11.5387 12.777 11.1735 12.777 10.7356V10.334L14.1518 9.56723C14.3987 9.42132 14.5747 9.16565 14.5747 8.83718V7.63249C14.5747 5.95309 13.2705 5.07715 12.0015 5.07715C10.6973 5.07715 9.42823 5.95335 9.42823 7.63249C9.39282 8.07048 9.74543 8.43562 10.2037 8.43562L10.2039 8.43563Z"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0.6"
                />
                <path
                    d="M12.0003 12.4619C10.44 12.4619 10.44 14.3081 12.0003 14.3081C13.5606 14.3081 13.5606 12.4619 12.0003 12.4619Z"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0.6"
                />
            </g>
            <defs>
                <clipPath id="clip0_412_4351">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default FaqsIcon;
