const OrderABoxIcon = () => {
    return (
        <svg
            width="22"
            height="26"
            viewBox="0 0 22 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.8791 25H3.12492C1.95488 25 1 24.0513 1 22.8888V3.11121C1.00371 1.94872 1.95488 1 3.12492 1H15.5763C15.6819 1 15.7802 1.04503 15.8481 1.12371L20.9056 6.77096C20.966 6.83842 21 6.92464 21 7.01103V22.8886C21 24.0511 20.0488 24.9998 18.8788 24.9998L18.8791 25ZM3.12492 1.72788C2.35875 1.72788 1.73213 2.34665 1.73213 3.11169V22.8893C1.73213 23.6505 2.35858 24.2731 3.12492 24.2731H18.8752C19.6414 24.2731 20.268 23.6507 20.268 22.8893V7.15051L15.4145 1.72827L3.12492 1.72788Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.8"
            />
            <path
                d="M20.6327 7.37481H15.5751C15.3712 7.37481 15.209 7.21359 15.209 7.01103V1.36378C15.209 1.16121 15.3712 1 15.5751 1C15.779 1 15.9413 1.16121 15.9413 1.36378V6.6476H20.6326C20.8365 6.6476 20.9988 6.80882 20.9988 7.01138C20.9988 7.21378 20.8363 7.375 20.6326 7.375L20.6327 7.37481Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.8"
            />
            <path
                d="M6.12651 11.5523C6.02845 11.5523 5.9378 11.5148 5.86602 11.4474L4.93762 10.525C4.79423 10.3825 4.79423 10.1536 4.93762 10.0112C5.08101 9.86873 5.31135 9.86872 5.45472 10.0112L6.12651 10.6786L7.90803 8.90861C8.05142 8.76614 8.28175 8.76614 8.42513 8.90861C8.56852 9.05107 8.56852 9.27992 8.42513 9.42237L6.387 11.4474C6.31522 11.5148 6.22086 11.5523 6.1265 11.5523H6.12651Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.8"
            />
            <path
                d="M16.8127 10.5401H10.3661C10.1623 10.5401 10 10.3788 10 10.1763C10 9.97371 10.1623 9.8125 10.3661 9.8125H16.8088C17.0127 9.8125 17.1749 9.97371 17.1749 10.1763C17.1786 10.375 17.0127 10.5401 16.8127 10.5401Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.8"
            />
            <path
                d="M6.12651 16.1949C6.03215 16.1949 5.9378 16.1611 5.86602 16.0899L4.93762 15.1675C4.79423 15.0251 4.79423 14.7962 4.93762 14.6538C5.08101 14.5113 5.31135 14.5113 5.45472 14.6538L6.12651 15.3212L7.90803 13.5512C8.05142 13.4087 8.28175 13.4087 8.42513 13.5512C8.56852 13.6936 8.56852 13.9225 8.42513 14.065L6.387 16.0899C6.31151 16.1574 6.21716 16.1949 6.1265 16.1949H6.12651Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.8"
            />
            <path
                d="M16.8127 15.1826H10.3661C10.1623 15.1826 10 15.0214 10 14.8189C10 14.6163 10.1623 14.4551 10.3661 14.4551H16.8088C17.0127 14.4551 17.1749 14.6163 17.1749 14.8189C17.1786 15.0176 17.0125 15.1826 16.8125 15.1826H16.8127Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.8"
            />
            <path
                d="M6.12651 20.8385C6.03215 20.8385 5.9378 20.8046 5.86602 20.7335L4.93762 19.8111C4.79423 19.6686 4.79423 19.4398 4.93762 19.2973C5.08101 19.1549 5.31135 19.1549 5.45472 19.2973L6.12651 19.9648L7.90803 18.1947C8.05142 18.0523 8.28175 18.0523 8.42513 18.1947C8.56852 18.3372 8.56852 18.5661 8.42513 18.7085L6.387 20.7335C6.31151 20.801 6.21716 20.8385 6.1265 20.8385H6.12651Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.8"
            />
            <path
                d="M16.8127 19.8252H10.3661C10.1623 19.8252 10 19.664 10 19.4614C10 19.2589 10.1623 19.0977 10.3661 19.0977H16.8088C17.0127 19.0977 17.1749 19.2589 17.1749 19.4614C17.1748 19.664 17.0125 19.8252 16.8125 19.8252H16.8127Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.8"
            />
        </svg>
    );
};
export default OrderABoxIcon;
