const RightArrowSVG = () => {
    return (
        <svg
            width="23"
            height="25"
            viewBox="0 0 23 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 1L16.5429 11.5429C16.6249 11.6198 16.6902 11.7128 16.7349 11.816C16.7796 11.9191 16.8026 12.0304 16.8026 12.1429C16.8026 12.2553 16.7796 12.3666 16.7349 12.4698C16.6902 12.573 16.6249 12.6659 16.5429 12.7429L6 23.2857"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default RightArrowSVG;
